import { ReportFornitoreFilter } from "@/pages/gestioneVendite/reportFornitore/reportFornitoreFilter";
import { FileInvioOnLine } from "./FileInvioOnLine";

export default class ReportFornitoreFilterItem {
	/**
	 *
	 */
	constructor() {
		this.filters = [];
	}

	filters: ReportFornitoreFilter[];
}
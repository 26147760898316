import { render, staticRenderFns } from "./reportFornitore.vue?vue&type=template&id=11ff4bc1&"
import script from "./reportFornitore.vue.ts?vue&type=script&lang=ts&"
export * from "./reportFornitore.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
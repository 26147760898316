export class ReportFornitoreFilter {
		constructor() {			
			this.filterType = 0;
			this.values = [];
			this.clause = 'in';

			this.variant = "info";
			this.textVariant = "white";
		}

        filterType: number;

        values: string[];
        clause: string;

        variant: string;
        textVariant: string;
    }
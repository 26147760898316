import ReportFornitoreFilterItem from "./ReportFornitoreFilterItem";
import { ReportFornitoriModel } from "./ReportFornitoriModel";
import { ReportSendType } from "./ReportSendType";
import SchedulingItem from "./SchedulingItem";

export default class ReportFornitoreItem {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.isActive = false;
		this.name = "new report";
		this.exportFileName = "";
		this.exportModel = 0;
		this.sendType = ReportSendType.email;
		this.recipients = "";
		this.recipientsCC = "";
		this.emailObject = "";
		this.emailSender = "";
		this.ftpUrl = "";
		this.ftpUser = "";
		this.ftpPassword = "";
		this.scheduling = new SchedulingItem();
		this.filterItems = new ReportFornitoreFilterItem();
		this.columns = [];
		this.isInEditMode = true;
		this.selectedTime = "";
	}


	itemID: number;
	name: string;
	exportModel: number;
	exportFileName: string;
	sendType: ReportSendType;
	recipients: string;
	recipientsCC: string;
	emailObject: string;
	emailSender: string;
	ftpUrl: string;
	ftpUser: string;
	ftpPassword: string;
	scheduling: SchedulingItem;
	filterItems: ReportFornitoreFilterItem;
	columns: string[];
	isActive: boolean;

	//client prop
	isInEditMode: boolean;
	selectedTime: string;
}
import { DayOfWeek } from "../dayOfWeek";
import { SpecialDayOfMonth } from "./SpecialDayOfMonth";

export default class SchedulingItem {
	constructor() {
		this.dayOfWeeks = [];
		this.executeTimes = [];

		this.specialDaysOfMonth = "";
	}
	dayOfWeeks: DayOfWeek[];
	executeTimes: string[];

	specialDaysOfMonth: any;
	useTime: boolean;
}
